import { Link, Stack, Box } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import OfferDetailsHeader from './OfferDetailsHeader';
import OfferDetailsOne from './OfferDetailsOne';
import useStyles from './Footer.styles';
import Split from '../../services/Split';
import logomark from '../../images/logomark-white-1x.png';

function Footer({
  offerDetailsRef,
  offerDetailsOneRef,
}: {
  offerDetailsRef: RefObject<null | HTMLDivElement>;
  offerDetailsOneRef: RefObject<null | HTMLDivElement>;
}) {
  const [promotionDisclaimerText, setPromotionDisclaimerText] = useState();

  useEffect(() => {
    Split.getTreatmentWithConfig('Promotion')
      .then(({ treatment, config }) => {
        if (treatment === 'on' && config) {
          const promotionDetails = JSON.parse(config);
          setPromotionDisclaimerText(promotionDetails.disclaimerText);
        }
      })
      .catch();

    return () => {
      Split.destrotySplitClient();
    };
  }, []);

  const { classes } = useStyles();
  return (
    <div className="rkt-GlobalFooter">
      <footer className="rkt-CenteredColumn rkt-pv4 rkt-ph7">
        <div className="rkt-GlobalFooter__brands-header rkt-mv6 rkt-pv3">
          <img
            className="rkt-GlobalFooter__brands-header-image rkt-ImgResponsive rkt-mb2"
            alt="Rocket Logo"
            src={logomark}
          />
        </div>
        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-mb8">
          <Stack
            direction={{ 0: 'column', 5: 'row' }}
            className="
            rkt-Stack rkt-Stack--split@5
            rkt-Stack__item
          "
          >
            <div className="rkt-Stack__item rkt-Stack__item--half@5">
              <h6 className="rkt-Heading-28 rkt-Color--white rkt-pb4">Rocket Mortgage</h6>
              <Stack
                rowGap={3}
                component="ul"
                className="rkt-ListStyle--none rkt-ml0 rkt-pl0 rkt-mt0 rkt-pr5"
              >
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/email-policy?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email and Text Policy
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketaccount.com/#/privacy-policy?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Security and Privacy
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketaccount.com/#/do-not-sell-my-personal-information?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Do Not Sell My Information
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/disclosures-licenses?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Disclosures and Licenses
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://www.rocketmortgage.com/legal/terms-of-use?qls=RFR_socrfrff.0123456789"
                    classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </Link>
                </li>
              </Stack>
            </div>
            <Stack
              className="rkt-Stack__item rkt-Stack__item--half@5"
              sx={{
                flexDirection: { md: 'column' },
                alignItems: { md: 'left' },
                paddingTop: { xs: 2, md: 0 },
              }}
            >
              <div>
                <h6 className="rkt-Heading-28 rkt-Color--white rkt-pb4">Support and Feedback </h6>
                <Stack
                  rowGap={3}
                  component="ul"
                  className="rkt-ListStyle--none rkt-ml0 rkt-pl0 rkt-mt0"
                >
                  <li>
                    <Link
                      href="https://www.rocketmortgage.com/opt-out?qls=RFR_socrfrff.0123456789"
                      classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Communication Opt-Out
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://surveys.rocketmortgage.com/jfe/form/SV_8rkw7oGYD5W6h3D"
                      classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Give Us Your Feedback
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="https://www.rocketmortgage.com/site-accessibility?qls=RFR_socrfrff.0123456789"
                      classes={{ root: 'rkt-Link rkt-Link--on-dark' }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Site Accessibility
                    </Link>
                  </li>
                </Stack>
              </div>
            </Stack>
          </Stack>
        </Stack>
        <div className="rkt-mt2 rkt-pt7 rkt-mb8 rkt-pb3">
          <h6 className="rkt-Heading-28 rkt-Color--white rkt-mb3">Legal Information</h6>
          <p className="rkt-Caption-12 rkt-Color--white">
            NMLS #3030 &#45;{' '}
            <Link
              href="https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/3030?qls=RFR_socrfrff.0123456789"
              className="rkt-Caption-12 rkt-Color--white rkt-Link rkt-Link--on-dark"
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              NMLS consumer access page
            </Link>
          </p>
          <p className="rkt-Caption-12 rkt-Color--white rkt-mb3">
            Rocket Mortgage, 1050 Woodward Avenue, Detroit, MI 48226-1906.
          </p>
          <p className="rkt-Caption-12 rkt-Color--white">
            ©2000 - 2025 Rocket Mortgage. All rights reserved. Lending services provided by Rocket
            Mortgage, a subsidiary of Rocket Companies, Inc. (NYSE: RKT).
          </p>
        </div>

        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-pv2 rkt-mv7">
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <Box sx={{ marginLeft: { xs: '.625rem' }, marginRight: { md: '.75rem' } }}>
              <OfferDetailsHeader ref={offerDetailsRef} />
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                <OfferDetailsOne ref={offerDetailsOneRef} />
                Referral clients will receive a lender credit equal to 1.5% of the loan amount. This
                offer is only available to clients who lock their interest rate while completing the
                application process on or after November 25, 2024. This offer cannot be
                retroactively applied to previously closed loans or previously locked loans. Offer
                does not apply to new loans submitted through a mortgage broker, non-agency jumbo
                loans, non-QM loans, C-product loans (Schwab), portfolio loans, Rocket jumbo loans
                or team member loans. This offer is only available to clients who are referred by
                team members of Amrock LLC, Bedrock Management Services LLC, Core Digital Media,
                Detroit Labs, Detroit Venture Partners, For Sale By Owner, Rocket Mortgage, Rocket
                Community Fund, RKT Holdings LLC, Rock FOC Technologies Canada ULC, Rocket Homes
                Real Estate LLC, Rocket Money, RockLoans Marketplace LLC, Rock Executive Security
                LLC, Rock Security LLC, Rock Ventures, Sift, Woodward Capital Management LLC, and
                Woodward Original, including interns, and who have been submitted through the Rocket
                Mortgage internal referral site, applied through a team member&apos;s referral link
                or through contact from the Referral Team due to direct referral from an eligible
                team member. Rocket Mortgage reserves the right to cancel this offer at any time.
                Acceptance of this offer constitutes acceptance of these terms and conditions, which
                are subject to change at the sole discretion of Rocket Mortgage. Offer is
                nontransferable. This is not a commitment to lend. Additional
                restrictions/conditions may apply. Offer not valid with any additional discounts or
                promotions.
              </p>
            </Box>
          </Stack>
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <Box sx={{ paddingTop: { xs: 4, md: 0 }, marginLeft: { md: '.75rem' } }}>
              <h6 className="rkt-Heading-28 rkt-pb4 rkt-Color--white caption">
                Detalles De La Oferta
              </h6>
            </Box>
            <Box sx={{ marginLeft: { xs: '.625rem', md: '1.375rem' } }}>
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                <sup className={classes.superscript}>1</sup>
                Los clientes recomendados recibirán un crédito del prestamista igual al 1,5% del
                monto del préstamo. Esta oferta solo está disponible para los clientes que congelen
                su tasa de interés mientras completan el proceso de solicitud el 25 de noviembre de
                2024 o después. Esta oferta no se puede aplicar de manera retroactiva a préstamos
                cerrados o congelados antes de esa fecha. La oferta no se aplica a préstamos nuevos
                que se presenten mediante un corredor hipotecario, préstamos jumbo que no sean de
                una agencia, préstamos que no sean QM, préstamos de producto C (Schwab), préstamos
                de cartera, préstamos jumbo de Rocket o préstamos para miembros del equipo. Esta
                oferta solo está disponible para clientes recomendados por miembros del equipo de
                Amrock LLC, Bedrock Management Services LLC, Core Digital Media, Detroit Labs,
                Detroit Venture Partners, For Sale By Owner, Rocket Mortgage, Rocket Community Fund,
                RKT Holdings LLC, Rock FOC Technologies Canada ULC, Rocket Homes Real Estate LLC,
                Rocket Money, RockLoans Marketplace LLC, Rock Executive Security LLC, Rock Security
                LLC, Rock Ventures, Sift, Woodward Capital Management LLC y Woodward Original,
                incluyendo los pasantes, y que hayan presentado una solicitud por medio del sitio
                interno de recomendaciones de Rocket Mortgage, por medio del enlace de
                recomendaciones de un miembro del equipo o por medio del contacto del Canal de
                Recomendaciones por recomendación directa de un miembro del equipo elegible. Rocket
                Mortgage se reserva el derecho de cancelar esta oferta en cualquier momento. La
                aceptación de esta oferta implica la aceptación de estos términos y condiciones, que
                están sujetos a cambios a criterio exclusivo de Rocket Mortgage. La oferta no es
                transferible. Esto no es un compromiso de préstamo. Puede haber otras restricciones
                o condiciones. La oferta no es válida con ningún otro descuento ni promoción.
              </p>
            </Box>
          </Stack>
        </Stack>

        {promotionDisclaimerText && (
          <p
            className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300"
            style={{ marginBottom: '20px' }}
          >
            {promotionDisclaimerText}
          </p>
        )}

        <Stack className="rkt-Stack rkt-Stack--split@5 rkt-pt2 rkt-mt7">
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5">
            <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
              Rocket Mortgage, LLC, Rocket Homes Real Estate LLC, RockLoans Marketplace LLC (doing
              business as Rocket Loans), Rocket Auto LLC, Rocket Solar, LLC and Rocket Money are
              separate operating subsidiaries of Rocket Companies, Inc. (NYSE: RKT). Each company is
              a separate legal entity operated and managed through its own management and governance
              structure as required by its state of incorporation and applicable legal and
              regulatory requirements.
            </p>
          </Stack>
          <Stack className="rkt-Stack__item rkt-Stack__item--half@5" pt={{ xs: 4, md: 0 }}>
            <Box sx={{ ml: { md: '1.5rem' } }}>
              <p className="rkt-Body-14 rkt-Color--white caption rkt-FontWeight--300">
                Rocket Mortgage, LLC, Rocket Homes Real Estate LLC, RockLoans Marketplace LLC (que
                también opera como Rocket Loans), Rocket Auto LLC, Rocket Solar, LLC y Rocket Money
                son subsidiarias independientes de Rocket Companies, Inc. (NYSE: RKT). Cada compañía
                es una entidad legal separada, operada y administrada por su propia estructura de
                dirección y administración, según lo exige el estado donde está constituida y los
                requisitos legales y normativos aplicables.
              </p>
            </Box>
          </Stack>
        </Stack>
      </footer>
    </div>
  );
}

export default Footer;

import { Box } from '@mui/material';
import logo from '../../images/L-RocketMortgage-RGB.png';
import logoStacked from '../../images/L-RocketMortgage-Stacked-RGB.png';
import useStyles from './Header.styles';

function Header() {
  const { classes } = useStyles();

  return (
    <header className={classes.header}>
      <Box className={classes['header-content']}>
        <picture>
          <source media="(max-width: 768px)" srcSet={logoStacked} />
          <source media="(min-width: 769px)" srcSet={logo} />
          <img src={logo} alt="Rocket Mortgage Logo" height="56px" />
        </picture>
      </Box>
    </header>
  );
}

export default Header;

import { Stack, Link, Card } from '@mui/material';
import { FormEvent, RefObject } from 'react';
import { ReactComponent as IconArrowDownward } from '../../images/arrow-downward.svg';
import { TeamMember } from '../../types/TeamMember';
import useStyles from './OfferSummary.styles';

function OfferSummary({
  teamMember,
  offerDetailsRef,
  offerDetailsOneRef,
}: {
  teamMember: TeamMember | null;
  offerDetailsRef: RefObject<null | HTMLDivElement>;
  offerDetailsOneRef: RefObject<null | HTMLDivElement>;
}) {
  const { classes } = useStyles();
  const scrollToOfferDetails = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsRef?.current) {
      offerDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToOfferDetailsOne = (e: FormEvent) => {
    e.preventDefault();

    if (offerDetailsOneRef?.current) {
      offerDetailsOneRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Card
        className={`rkt-Card rkt-pa8 ${classes['container-680']}`}
        style={{ maxWidth: '680px', height: '100%' }}
      >
        <div className={`rkt-mb4 ${classes['container-680']}`}>
          {teamMember?.firstName && teamMember?.lastInitial && (
            <h1 className="rkt-Heading-56 rkt-Heading-56--responsive">
              Great news! You&apos;re eligible for special mortgage loan savings
              <span
                onClick={scrollToOfferDetailsOne}
                onKeyDown={scrollToOfferDetailsOne}
                className="supLink rkt-Color--black"
                role="button"
                tabIndex={-2}
              >
                ¹
              </span>{' '}
              because {teamMember?.firstName} {teamMember?.lastInitial}. referred you.
            </h1>
          )}
          {!teamMember?.firstName && !teamMember?.lastInitial && (
            <h1 className="rkt-Heading-56 rkt-Heading-56--responsive">
              Great news! You&apos;re eligible for special mortgage loan savings
              <span
                onClick={scrollToOfferDetailsOne}
                onKeyDown={scrollToOfferDetailsOne}
                className={`${classes.supLink} rkt-Color--black`}
                role="button"
                tabIndex={-2}
              >
                ¹
              </span>{' '}
              because you were referred.
            </h1>
          )}
        </div>
        <div className={`${classes['container-680']}`}>
          <Stack spacing={4}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                onClick={scrollToOfferDetails}
                className={`${classes['cursor-pointer']} offer-details-link rkt-Link`}
                color="secondary"
              >
                View offer details <IconArrowDownward style={{ marginBottom: '-6px' }} />
              </Link>
            </div>
          </Stack>
        </div>
      </Card>
      <div className={`rkt-TextAlign--center rkt-mv8 ${classes['container-680']}`}>
        <p className="rkt-Heading-36 rkt-Heading-36--responsive">
          Submit your information and we will connect you to a mortgage loan officer today.
        </p>
        <p className="rkt-Color--gray-600">All fields are mandatory</p>
      </div>
    </>
  );
}

export default OfferSummary;

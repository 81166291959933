/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Link,
} from '@mui/material';
import states from 'ustates';
import { FieldValues, useForm } from 'react-hook-form';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import { ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TeamMember } from '../../types/TeamMember';
import { FormData } from '../../types/FormData';
import Api from '../../services/Api';
import useStyles from './LeadForm.styles';

declare global {
  interface Window {
    adobeDataLayer: any[];
  }
}

function LeadForm({
  abbreviationId,
  teamMember,
}: {
  abbreviationId: string | undefined;
  teamMember: TeamMember | null;
}) {
  const { classes } = useStyles();
  useEffect(() => {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push({
      event: 'PAGEVIEW',
    });
    window.adobeDataLayer.push({
      event: 'FORM_VIEW',
      metadata: {
        form_name: 'rocket refer lead form', // if this is known by a different name, use that
        type: 'mortgage referral',
      },
    });
  }, []);

  const navigate = useNavigate();
  const {
    register,
    clearErrors,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (values: FieldValues) => {
    const formData = {
      abbreviationId: values.abbreviationId,
      loanType: values.loadType,
      firstName: values.firstName,
      lastName: values.lastName,
      state: values.state,
      phone: values.phone,
      email: values.email,
      isSpanishSpeaking: values.isSpanishSpeaking,
      relationship: values.relationship,
    } as FormData;
    Api.submitLead(formData)
      .then(() => {
        navigate('/success');
      })
      .catch(() => {
        document
          .getElementsByClassName('LeadForm__error-message')[0]
          .scrollIntoView({ behavior: 'smooth' });
      });
  };

  const onPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let result = e.target.value;

    // Format after the user has entered at least three digits
    if (/(.?\d){4,}/.test(result)) {
      result = formatIncompletePhoneNumber(result, 'US');
    }

    setValue('phone', result);
  };

  return (
    <form
      className={`rkt-Display--flex-1 ${classes['container-680']}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input hidden value={abbreviationId} {...register('abbreviationId', { required: true })} />
      <Stack spacing={7}>
        <FormControl
          id="relationship"
          variant="filled"
          component="fieldset"
          classes={{ root: 'rkt-Width-100' }}
        >
          {teamMember?.firstName && teamMember?.lastInitial && (
            <FormLabel component="legend" classes={{ root: 'rkt-Heading-24 rkt-mb4' }}>
              How do you know {teamMember?.firstName} {teamMember?.lastInitial}.?
            </FormLabel>
          )}
          {!teamMember?.firstName && !teamMember?.lastInitial && (
            <FormLabel component="legend" classes={{ root: 'rkt-Heading-24 rkt-mb4' }}>
              How do you know the person who referred you?
            </FormLabel>
          )}
          <RadioGroup aria-label="relationship">
            <Stack
              spacing={3}
              direction={{ 0: 'column' }}
              className="rkt-HugeInputContainer no-max-width"
            >
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value="FriendColleague"
                  control={
                    <Radio color="secondary" {...register('relationship', { required: true })} />
                  }
                  label="Friend or colleague"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value="Family"
                  control={
                    <Radio color="secondary" {...register('relationship', { required: true })} />
                  }
                  label="Family member"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value="Acquaintance"
                  control={
                    <Radio color="secondary" {...register('relationship', { required: true })} />
                  }
                  label="Acquaintance"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
            </Stack>
          </RadioGroup>
          {errors?.relationship && (
            <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt3">Please select an option.</p>
          )}
        </FormControl>
        <FormControl
          id="loanType"
          variant="filled"
          component="fieldset"
          classes={{ root: 'rkt-Width-100' }}
        >
          <FormLabel component="legend" classes={{ root: 'rkt-Heading-24 rkt-mb4' }}>
            What would you like to do?
          </FormLabel>
          <RadioGroup aria-label="loanType" {...register('loanType', { required: true })}>
            <Stack spacing={3} className="rkt-HugeInputContainer no-max-width">
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value="purchase"
                  control={
                    <Radio color="secondary" {...register('loanType', { required: true })} />
                  }
                  label="I want to buy a new home"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value="refinance"
                  control={
                    <Radio color="secondary" {...register('loanType', { required: true })} />
                  }
                  label="I want to refinance my current home"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
            </Stack>
          </RadioGroup>
          {errors?.loanType && (
            <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt3">Please select an option.</p>
          )}
        </FormControl>
        <div>
          {watch('loanType') === 'purchase' && (
            <legend className="rkt-Heading-24 rkt-mb4">
              Where is the property you&apos;re buying located?
            </legend>
          )}
          {watch('loanType') === 'refinance' && (
            <legend className="rkt-Heading-24 rkt-mb4">
              Where is the property you&apos;re refinancing located?
            </legend>
          )}
          {!watch('loanType') && (
            <legend className="rkt-Heading-24 rkt-mb4">Where is the property located?</legend>
          )}
          <FormControl
            error={errors?.state != null}
            variant="filled"
            classes={{ root: 'rkt-FormField rkt-InputContainer' }}
            style={{ width: '100%' }}
          >
            <InputLabel classes={{ root: 'rkt-Color--yellow-600' }} component="legend">
              State
            </InputLabel>
            <Select
              classes={{ select: 'rkt-Input' }}
              labelId="state-select-label"
              id="state-select"
              inputProps={{ 'data-testid': 'state-select' }}
              defaultValue=""
              color="secondary"
              {...register('state', { required: true })}
              onChange={() => clearErrors('state')}
            >
              {states.index.map((state: { code: string; name: string }) => (
                <MenuItem
                  classes={{ root: 'rkt-MenuItem rkt-Label-16' }}
                  key={state.code}
                  value={state.code}
                >
                  {state.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.state && (
              <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt1 rkt-ml3">
                Please select a state.
              </p>
            )}
          </FormControl>
        </div>
        <Stack spacing={3}>
          <div>
            <legend className="rkt-Heading-24 rkt-mb4">What is your name?</legend>
            <FormControl
              variant="filled"
              classes={{ root: 'rkt-FormField rkt-Width--100' }}
              style={{ width: '100%' }}
            >
              <TextField
                id="first-name"
                color="secondary"
                classes={{ root: 'rkt-Input MuiSelect-select' }}
                label="First name"
                variant="filled"
                size="medium"
                error={errors?.firstName != null}
                {...register('firstName', { required: true, pattern: /^[^%<>^$@!#]+$/ })}
              />
              {errors?.firstName && (
                <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt1 rkt-ml3">
                  Please enter your first name.
                </p>
              )}
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="filled"
              classes={{ root: 'rkt-FormField rkt-Width--100' }}
              style={{ width: '100%' }}
            >
              <TextField
                id="last-name"
                color="secondary"
                classes={{ root: 'rkt-Input MuiSelect-select' }}
                label="Last name"
                variant="filled"
                size="medium"
                error={errors?.lastName != null}
                {...register('lastName', { required: true, pattern: /^[^%<>^$@!#]+$/ })}
              />
              {errors?.lastName && (
                <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt1 rkt-ml3">
                  Please enter your last name.
                </p>
              )}
            </FormControl>
          </div>
        </Stack>
        <Stack spacing={3}>
          <div>
            <legend className="rkt-Heading-24 rkt-mb4">What is your contact information?</legend>
            <FormControl
              variant="filled"
              classes={{ root: 'rkt-FormField rkt-Width--100' }}
              style={{ width: '100%' }}
            >
              <TextField
                id="phone"
                color="secondary"
                classes={{ root: 'rkt-Input MuiSelect-select' }}
                label="Phone number"
                variant="filled"
                size="medium"
                placeholder="(000) 000-0000"
                type="tel"
                error={errors?.phone != null}
                {...register('phone', {
                  required: true,
                  pattern: /\([\d]{3}\) [\d]{3}-[\d]{4}/,
                  onChange: onPhoneChange,
                })}
              />
              {errors?.phone && (
                <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt1 rkt-ml3">
                  Please enter your 10 digit phone number.
                </p>
              )}
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="filled"
              classes={{ root: 'rkt-FormField rkt-Width--100' }}
              style={{ width: '100%' }}
            >
              <TextField
                id="email"
                color="secondary"
                classes={{ root: 'rkt-Input MuiSelect-select' }}
                label="Email address"
                variant="filled"
                size="medium"
                error={errors?.email != null}
                {...register('email', {
                  required: true,
                  pattern:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
              {errors?.email && (
                <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt1 rkt-ml3">
                  Please enter your email address.
                </p>
              )}
            </FormControl>
          </div>
        </Stack>
        <FormControl
          id="loanType"
          variant="filled"
          component="fieldset"
          classes={{ root: 'rkt-Width-100' }}
        >
          <FormLabel component="legend" classes={{ root: 'rkt-Heading-24 rkt-mb4' }}>
            Our home loan experts can communicate fluently in English and Spanish. Which would you
            prefer?
          </FormLabel>
          <RadioGroup aria-label="loanType" name="loanType">
            <Stack
              spacing={3}
              direction={{ 0: 'column' }}
              className="rkt-HugeInputContainer no-max-width"
            >
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      color="secondary"
                      data-testid="is-spanish-speaking-yes"
                      {...register('isSpanishSpeaking', { required: true })}
                    />
                  }
                  label="English"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
              <div className={`rkt-RadioHuge no-max-width ${classes['radio-group-item']}`}>
                <FormControlLabel
                  value
                  control={
                    <Radio
                      color="secondary"
                      {...register('isSpanishSpeaking', { required: true })}
                    />
                  }
                  label="Spanish"
                  classes={{
                    root: 'rkt-RadioHuge__label',
                  }}
                />
              </div>
            </Stack>
          </RadioGroup>
          {errors?.isSpanishSpeaking && (
            <p className="rkt-Caption-12 rkt-Color--yellow-600 rkt-mt3">Please select an option.</p>
          )}
        </FormControl>
        <div
          className={`rkt-Caption-12 rkt-Color--gray-600 rkt-TextAlign--left ${classes['container-680']}`}
        >
          <div className="rkt-mb4">
            By providing your contact info and selecting Confirm and Continue below, you agree to
            our{' '}
            <Link
              href="https://www.rocketaccount.com/#/privacy-policy"
              classes={{
                root: `rkt-TextDecoration--underline ${classes['terms-of-service-link']}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link
              href="https://www.rocketmortgage.com/legal/terms-of-use"
              classes={{
                root: `rkt-TextDecoration--underline ${classes['terms-of-service-link']}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </Link>
            , which includes your agreement to arbitrate claims related to the Telephone Consumer
            Protection Act. You also expressly consent by electronic signature to receive sales,
            marketing and other calls and texts, including those sent by any automated system or
            other means for selecting and dialing telephone numbers, or using an artificial or
            prerecorded voice message when a connection is completed, from Rocket Mortgage at the
            telephone number you provided, even if that telephone number is on a do-not-call list.
            Agreement to receive such calls or texts is not a condition of purchasing good or
            services from us.
          </div>
          <div>
            Al proporcionar su información de contacto y hacer clic en botón abajo, acepta nuestra{' '}
            <Link
              href="https://www.rocketaccount.com/#/privacy-policy-spanish"
              classes={{
                root: `rkt-TextDecoration--underline ${classes['terms-of-service-link']}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidad
            </Link>{' '}
            y{' '}
            <Link
              href="https://www.rocketmortgage.com/es/legal/terminos-de-uso"
              classes={{
                root: `rkt-TextDecoration--underline ${classes['terms-of-service-link']}`,
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos de Uso
            </Link>
            , que incluye su acuerdo para arbitrar reclamaciones relacionadas con la Ley de
            Protección al Consumidor Telefónico. Además, otorga su consentimiento expreso, mediante
            firma electrónica, para recibir llamadas y mensajes de texto de ventas, marketing y
            otros tipos, incluidos aquellos enviados por cualquier sistema automatizado u otros
            medios para seleccionar y marcar números telefónicos, o mediante un mensaje de voz
            artificial o pregrabado al completarse una conexión, de Rocket Mortgage al número
            telefónico que proporcionó, incluso si dicho número está en una lista de no llamar. La
            aceptación de recibir tales llamadas o mensajes de texto no constituye una condición
            para la compra de bienes o servicios de nuestra parte.
          </div>
        </div>

        <div className="rkt-TextAlign--center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            classes={{ root: 'rkt-Button rkt-Button--large' }}
            className="rkt-mb8"
          >
            Confirm and Continue
          </Button>
        </div>
      </Stack>
    </form>
  );
}

export default LeadForm;

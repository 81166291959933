import { forwardRef } from 'react';

export type Ref = null | HTMLDivElement;

const OfferDetailsHeader = forwardRef<Ref>((_, ref) => (
  <div ref={ref}>
    <h6 className="rkt-Heading-28 rkt-pb4 rkt-Color--white caption">Offer Details</h6>
  </div>
));

export default OfferDetailsHeader;

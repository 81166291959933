import '../styles/App.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Header from './Header/Header';
import { TeamMember } from '../types/TeamMember';
import Api from '../services/Api';
import Footer from './Footer/Footer';
import LeadForm from './LeadForm/LeadForm';
import OfferSummary from './OfferSummary/OfferSummary';

function LeadFormPage() {
  const { abbreviationId } = useParams<'abbreviationId'>();
  const [teamMember, setTeamMember] = useState<TeamMember | null>(null);
  const offerDetailsRef = useRef<null | HTMLDivElement>(null);
  const offerDetailsOneRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    const getTeamMember = async () => {
      if (abbreviationId) {
        setTeamMember(await Api.getTeamMember(abbreviationId));
      }
    };

    getTeamMember();
  }, [abbreviationId]);

  return (
    <>
      <Box px={{ xs: 3, md: 5 }}>
        <Header />
        <OfferSummary
          teamMember={teamMember}
          offerDetailsRef={offerDetailsRef}
          offerDetailsOneRef={offerDetailsOneRef}
        />
        <LeadForm abbreviationId={abbreviationId} teamMember={teamMember} />
      </Box>
      <Footer offerDetailsRef={offerDetailsRef} offerDetailsOneRef={offerDetailsOneRef} />
    </>
  );
}

export default LeadFormPage;
